/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import useAppConfig from '@core/app-config/useAppConfig'
import axios from '@axios'
import appsAndPages from './apps-and-pages'
// Array of sections
export default [...appsAndPages]

// console.log(localStorage.getItem('userData'));
let userData = JSON.parse(localStorage.getItem('userData'))
// alert(localStorage.getItem('emp_id'));
let arr = new Array()
// const resultPromise = axios.post(`${useAppConfig().AppUrl}global/getMenuAkses`, {emp_id: localStorage.getItem('emp_id')})
// const prom = result.then((response) => {
//     console.log(response.data);
//     return response.data
// })
// console.log(prom);
// let promise = getPromise(ALL_POKEMONS_URL);

// async function PromiseExample() {
//     try {
//         const resultPromise = await axios.post(`${useAppConfig().AppUrl}global/getMenuAkses`, {emp_id: localStorage.getItem('emp_id')})
//     //   const value = await resultPromise;
//       console.log(resultPromise);
//     // return resultPromise.data
//     } catch (error) {
//       console.log(error); //This is a rejected promise  👈
//     }
// }
  
// const getProm = PromiseExample();
// console.log(getProm);
// const menus = appsAndPages.getMenuAkses()
// console.log(menus);
// const menus = result.then((response) => {
//     // commit('logUserIn', response.data);
//     console.log(response.data);
//     // return response.data
//     let menu = new Array()
//         menu.push(
//             {
//                 title: 'Sales',
//                 icon: 'UsersIcon',
//                 children: [
//                   {
//                     title: 'List Pengajuan',
//                     route: 'apps-pengajuan-sales-list',
//                   },
//                 ],
//             },
//             {
//                 title: 'Monitoring',
//                 icon: 'SearchIcon',
//                 children: [
//                   {
//                     title: 'List Pengajuan',
//                     route: 'apps-monitoring-pengajuan-list',
//                   },
//                 ],
//             },
//         )
// })
// .catch((error) => {
//     console.log(error);
//     // commit('unAuthorisedUser', { error:error.response.data });
// })

// const menus = appsAndPages.getMenuAkses()
// console.log(menus);
// const res_menu = menus
// console.log(menus);

// let menu = new Array()
// if(userData.map_user_role == 'Admin'){
//     menu.push(
//         {
//             title: 'Master Data',
//             icon: 'SettingsIcon',
//             children: [
//               {
//                 title: 'Hak Akses',
//                 route: 'apps-hak-akses-list',
//               },
//             //   {
//             //     title: 'Divisi',
//             //     route: 'apps-divisi-list',
//             //   },
//             //   {
//             //     title: 'Segmen',
//             //     route: 'apps-segmen-list',
//             //   },
//               {
//                 title: 'Mapping Divisi',
//                 route: 'apps-mapping-divisi-list',
//               },
//               {
//                 title: 'Mapping User',
//                 route: 'apps-mapping-user-list',
//               },
//               {
//                 title: 'Approval Sales',
//                 route: 'apps-approval-sales-list',
//               },
//               {
//                 title: 'Kategori Produk',
//                 route: 'apps-kategori-produk-list',
//               },
//               {
//                 title: 'Email Mengetahui',
//                 route: 'apps-email-mengetahui-list',
//               },
//             ],
//         },
//         {
//             title: 'Sales',
//             icon: 'UsersIcon',
//             children: [
//               {
//                 title: 'List Pengajuan',
//                 route: 'apps-pengajuan-sales-list',
//               },
//             ],
//         },
//         {
//             title: 'Approval Sales',
//             icon: 'UserCheckIcon',
//             children: [
//               {
//                 title: 'List Pengajuan',
//                 route: 'apps-approval-list',
//               },
//             ],
//         },
//         {
//             title: 'Monitoring',
//             icon: 'SearchIcon',
//             children: [
//               {
//                 title: 'List Pengajuan',
//                 route: 'apps-monitoring-pengajuan-list',
//               },
//             ],
//         },
//     )
// }
// else if(userData.map_user_role == 'Sales'){
//     menu.push(
//         {
//             title: 'Sales',
//             icon: 'UsersIcon',
//             children: [
//               {
//                 title: 'List Pengajuan',
//                 route: 'apps-pengajuan-sales-list',
//               },
//             ],
//         },
//         {
//             title: 'Monitoring',
//             icon: 'SearchIcon',
//             children: [
//               {
//                 title: 'List Pengajuan',
//                 route: 'apps-monitoring-pengajuan-list',
//               },
//             ],
//         },
//     )
// }
// else if(userData.map_user_role == 'Approval Sales'){
//     menu.push(
//         {
//             title: 'Approval Sales',
//             icon: 'UserCheckIcon',
//             children: [
//               {
//                 title: 'List Pengajuan',
//                 route: 'apps-approval-list',
//               },
//             ],
//         },
//         {
//             title: 'Monitoring',
//             icon: 'SearchIcon',
//             children: [
//               {
//                 title: 'List Pengajuan',
//                 route: 'apps-monitoring-pengajuan-list',
//               },
//             ],
//         },
//     )
// }

// export default menu