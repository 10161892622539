<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <!--p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p-->
        <p class="user-name font-weight-bolder mb-0" style="color:#ffff;">
          {{ userData.nama }}
        </p>
        <span class="user-status" style="color:#ffff;">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>


    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

import axios from '@axios'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    async logout() {
      let userData = JSON.parse(localStorage.getItem('userData'));
      console.log(userData);
      const log = await axios.post(`${useAppConfig().AppUrl}global/insertLogAkses`, {
        'emp_id': userData.id, 'param': userData, url: window.location.origin, 'keterangan': 'user', 'status': 'logout'
      })
      console.log(log);
      if(log.status == 200){
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        localStorage.removeItem('bisnis_unit')
        localStorage.removeItem('emp_id')
        localStorage.removeItem('userLogin')
        localStorage.removeItem('trn_code')

        // Reset ability
        this.$ability.update(initialAbility)

        // Redirect to login page
        // this.$router.push({ name: 'auth-login' })
        if(localStorage.getItem('internal') == 1){
          this.$router.push({ name: 'auth-login' })
        }
        else{
          window.close()
        }
      }
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      
    },
  },
}
</script>
