export default [
  {
    title: 'Master Data',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Hak Akses',
        route: 'apps-hak-akses-list',
      },
      {
        title: 'Divisi',
        route: 'apps-divisi-list',
      },
      {
        title: 'Mapping Divisi',
        route: 'apps-mapping-divisi-list',
      },
      {
        title: 'Mapping User',
        route: 'apps-mapping-user-list',
      },
      {
        title: 'Approval Sales',
        route: 'apps-approval-sales-list',
      },
      {
        title: 'Kategori Produk',
        route: 'apps-kategori-produk-list',
      },
      {
        title: 'Email Mengetahui',
        route: 'apps-email-mengetahui-list',
      },
    ],
  },
  {
    title: 'Sales',
    icon: 'UsersIcon',
    children: [
      {
        title: 'List Pengajuan',
        route: 'apps-pengajuan-sales-list',
      },
    ],
  },
  {
    title: 'Approval Sales',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'List Pengajuan',
        route: 'apps-approval-list',
      },
    ],
  },
  {
    title: 'Monitoring',
    icon: 'SearchIcon',
    children: [
      {
        title: 'List Pengajuan',
        route: 'apps-monitoring-pengajuan-list',
      },
    ],
  },
  
]
// import useAppConfig from '@core/app-config/useAppConfig'
// import axios from '@axios'

// export default {
//   async getMenuAkses(){
//     let result = await axios.post(`${useAppConfig().AppUrl}global/getMenuAkses`, {emp_id: localStorage.getItem('emp_id')})
//       // console.log(result.data);
//     return result.data
//   },
// }